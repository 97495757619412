html,
body,
#app {
    height: 100%;
    background: white;
}

html {
    scrollbar-gutter: stable;
}

body {
    background-color: rgb(250, 250, 250);
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #6fa3a6;
    text-decoration: none;
}

.react-joyride__spotlight {
    transition: all 0.3s ease !important;
}

@keyframes shake-error-input {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    70%,
    90% {
        transform: translateX(-3px);
    }

    20%,
    40%,
    80% {
        transform: translateX(3px);
    }
}

[data-title]:before {
    position: absolute;
    content: attr(data-title);
    opacity: 0;
    z-index: 1;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: var(--primary-text-color);
    background-color: #f2f2f2;
    border-radius: 6px;
    padding: 2px 8px;
    top: -32px;
    width: max-content;
    display: none;
    transition: opacity 0.3s ease-out;
}

[data-title]:after {
    display: none;
    position: absolute;
    content: " ";
    border: 10px solid transparent;
    border-top-color: #f2f2f2;
    opacity: 0;
    z-index: 1;
    left: 15px;
    top: -5px;
    transition: opacity 0.3s ease-out;
}

[data-title]:hover:before {
    display: block;
    opacity: 1;
}

[data-title]:hover:after {
    display: block;
    opacity: 1;
}

[data-loading="true"] {
    pointer-events: none;
    background: linear-gradient(90deg, #f0f0f0 25%, #f7f7f7 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: modern-loading 2.5s infinite;
}

[data-spinning="true"] {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}

[data-spinning="true"]::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid var(--primary-color);
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}

@keyframes modern-loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}


@keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

:root {
    /* platform main colors */
    --dark-elevated-color: #1C1C1E;
    --dark-secondary-color: #8A8A8E;
    --dark-tertiary-color: #BFBFC1;
    --participant-grey-color: #87878A;
    --input-black-color: #111111;
    --input-grey-color: rgba(17, 17, 17, 0.48);
    --light-border-color: #DCDCDD;
    --success-color: #27AE60;
    --success-bg-color: #CBECD9;
    /* error variant #D24E4E */
    --error-color: #E74C3C;
    --error-bg-color: #F9E3E3;
    --error-border-color: #f5c6cb;
    --error-border-color: #f5c6cb;
    --warning-color: #ffe003;
    --warning-bg-color: #FFF0BF;
    --disabled-color: #E9E9EB;
    --tag-background-color: #F4F4F5;
    --tag-text-color: #4F4F4F;
    /* platform main colors */

    --primary-color: #79bafa;
    --secondary-color: #e5eef7;
    --primary-background-color: var(--primary-color);

    --primary-text-color: var(--dark-elevated-color);
    --secondary-text-color: var(--dark-elevated-color);
    --accent-text-color: var(--primary-color);

    /* misc variables */
    --shake-error-input-animation: shake-error-input 0.3s ease 1;
    --top-level-z-index: 100001;
}

:root [data-community="mipt"] {
    --primary-color: #176FC1;
    --primary-background-color: var(--primary-color);
    --secondary-color: #D9F6FF;
    --secondary-text-color: var(--dark-secondary-color);
    --accent-text-color: var(--primary-color);
}

:root [data-community="skolkovo"] {
    --primary-color: #050440;
    --primary-background-color: var(--primary-color);
    --secondary-color: #e3e3fd;

    --accent-text-color: var(--primary-color);
}

:root [data-community="startupstudio"] {
    --primary-color: #826BE9;
    --primary-background-color: var(--primary-color);
    --secondary-color: #C5D9E7;

    --accent-text-color: var(--primary-color);
}

:root [data-community="hseinc"] {
    --primary-color: #ff0000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #ffeeee;

    --accent-text-color: var(--primary-color);
}

:root [data-community="enjoyhannover"] {
    --primary-color: #003366;
    --primary-background-color: var(--primary-color);
    --secondary-color: #F4F7FA;
    --secondary-background-color: var(--secondary-color);
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: #FFC303;
}

:root [data-community="sit"] {
    --primary-color: #ff0000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #ffeeee;

    --accent-text-color: var(--primary-color);
}

:root [data-community="ism"] {
    --primary-color: #0065E2;
    --primary-background-color: var(--primary-color);
    --secondary-color: #DFE8E7;

    --accent-text-color: var(--primary-color);
}

:root [data-community="techno-startup"] {
    --primary-color: #050440;
    --primary-background-color: var(--primary-color);
    --secondary-color: #e3e3fd;

    --accent-text-color: var(--primary-color);
}

:root [data-community="vrmsu"] {
    --primary-color: #445273;
    --primary-background-color: var(--primary-color);
    --secondary-color: #dadce3;

    --accent-text-color: var(--primary-color);
}

:root [data-community="bournemouth"] {
    --primary-color: #d81476;
    --primary-background-color: var(--primary-color);
    --secondary-color: #f7d0e4;

    --accent-text-color: var(--primary-color);
}

:root [data-community="swgteam"] {
    --primary-color: #dd1f3d;
    --primary-background-color: var(--primary-color);
    --secondary-color: #c5c4c4;

    --accent-text-color: var(--primary-color);
}

:root [data-community="angelsdeck"] {
    --primary-color: #15161a;
    --primary-background-color: var(--primary-color);
    --secondary-color: #F4DC9F;

    --accent-text-color: var(--primary-color);
}

:root [data-community="annamoney"] {
    --primary-color: #12AD84;
    --primary-background-color: var(--primary-color);
    --secondary-color: rgba(0, 237, 137, 0.08);

    --primary-text-color: #4E4E4E;
    --secondary-text-color: var(--dark-elevated-color);
    --accent-text-color: #E9624E;
}

:root [data-community="zuu"] {
    --primary-color: #bc000e;
    --primary-background-color: var(--primary-color);
    --secondary-color: #c5c4c4;

    --accent-text-color: var(--primary-color);
}

:root [data-community="itmo"] {
    --primary-color: #3951e3;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FFE0BF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="synergy"] {
    --primary-color: #e99600;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FFF0BF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="thegarage"] {
    --primary-color: #213f5b;
    --primary-background-color: var(--primary-color);
    --secondary-color: #E3E7EA;

    --accent-text-color: var(--primary-color);
}

:root [data-community="phystech"] {
    --primary-color: #176FC1;
    --primary-background-color: var(--primary-color);
    --secondary-color: #D9F6FF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="cems"] {
    --primary-color: #f9b831;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FDEDCB;

    --accent-text-color: var(--primary-color);
}

:root [data-community="demo"] {
    --primary-color: #826BE9;
    --primary-background-color: var(--primary-color);
    --secondary-color: #C5D9E7;

    --accent-text-color: var(--primary-color);
}

:root [data-community="unitedinvestors"] {
    --primary-color: #000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FDEDCB;

    --accent-text-color: var(--primary-color);
}

:root [data-community="ajmanacademy"] {
    --primary-color: #1b4298;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FDEDCB;

    --accent-text-color: var(--primary-color);
}

:root [data-community="nw"] {
    /*renaissance bank community */
    --primary-color: #D41367;
    --primary-background-color: var(--primary-color);
    --secondary-color: #FBECF3;

    --accent-text-color: var(--primary-color);
}

:root [data-community="startech"] {
    --primary-color: #000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #6db1b2;


    --accent-text-color: var(--primary-color);
}

:root [data-community="jewishpoint"] {
    --primary-color: #0000FF;
    --primary-background-color: var(--primary-color);
    --secondary-color: #CCCCFF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="muivlab"] {
    --primary-color: #000000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #bbbbbb;

    --accent-text-color: var(--primary-color);
}

:root [data-community="rc"] {
    --primary-color: #e22721;
    --primary-background-color: var(--primary-color);
    --secondary-color: #4d8ba2;
    /*#eea7a5;*/

    --accent-text-color: var(--primary-color);
}

:root [data-community="startuphse"] {
    --primary-color: #ff0000;
    --primary-background-color: var(--primary-color);
    --secondary-color: #c5c4c4;

    --accent-text-color: var(--primary-color);
}

:root [data-community="hsebusiness"] {
    --primary-color: #bf71ff;
    --primary-background-color: var(--primary-color);
    --secondary-color: #bfaef8;

    --accent-text-color: var(--primary-color);
}

:root [data-community="websummit"] {
    --primary-color: #12AD84;
    --primary-background-color: var(--primary-color);
    --secondary-color: rgba(0, 237, 137, 0.08);

    --primary-text-color: #4E4E4E;
    --secondary-text-color: var(--dark-elevated-color);
    --accent-text-color: #E9624E;
}

:root [data-community="mipt-expertclub"] {
    --primary-color: #176FC1;
    --primary-background-color: var(--primary-color);
    --secondary-color: #D9F6FF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="columbiaalumni"] {
    --primary-color: #356DBB;
    --primary-background-color: var(--primary-color);
    --secondary-color: #D9F6FF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="multicarta"] {
    --primary-color: #176FC1;
    --primary-background-color: var(--primary-color);
    --secondary-color: #D9F6FF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="juniorproduct"] {
    --primary-color: #80a6c5;
    --primary-background-color: var(--primary-color);
    --secondary-color: #f1d593;

    --accent-text-color: var(--primary-color);
}

:root [data-community="virtualvalley"] {
    --primary-color: #1b2d67;
    --primary-background-color: var(--primary-color);
    --secondary-color: #9d9d9c;

    --accent-text-color: var(--primary-color);
}

:root [data-community="sfeducation"] {
    --primary-color: #548bbf;
    --primary-background-color: var(--primary-color);
    --secondary-color: #eaf0fc;

    --accent-text-color: var(--primary-color);
}

:root [data-community="ciao"] {
    --primary-color: #FF5757;
    --primary-background-color: var(--primary-color);
    --secondary-color: rgba(255, 87, 87, 0.3);

    --accent-text-color: var(--primary-color);
}

:root [data-community="kommunity"] {
    --primary-color: #004381;
    --primary-background-color: var(--primary-color);
    --secondary-color: #f8f8ff;
    --secondary-background-color: var(--secondary-color);
    --secondary-text-color: #404040;
    --accent-text-color: var(--primary-color);
}

:root [data-community="passeio"] {
    --primary-color: #0000FF;
    --primary-background-color: var(--primary-color);
    --secondary-color: #CCCCFF;

    --accent-text-color: var(--primary-color);
}

:root [data-community="varacommunity"] {
    --primary-color: #03ffc6;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="eur"] {
    --primary-color: #0c8066;
    --primary-background-color: var(--primary-color);
    --secondary-color: #aadbd1;

    --accent-text-color: var(--primary-color);
}

:root [data-community="helloworld"] {
    --primary-color: #040464;
    --primary-background-color: var(--primary-color);
    --secondary-color: #a5aeeb;

    --accent-text-color: var(--primary-color);
}

:root [data-community="dc"] {
    --primary-color: #00679f;
    --primary-background-color: var(--primary-color);
    --secondary-color: #a1cadb;

    --accent-text-color: var(--primary-color);
}

:root [data-community="powerup"] {
    --primary-color: #292927;
    --primary-background-color: var(--primary-color);
    --secondary-color: #ffd6bc;

    --accent-text-color: var(--primary-color);
}

:root [data-community="powerup-venezia"] {
    --primary-color: #292927;
    --primary-background-color: var(--primary-color);
    --secondary-color: #ffd6bc;

    --accent-text-color: var(--primary-color);
}

:root [data-community="powerup-napoli"] {
    --primary-color: #292927;
    --primary-background-color: var(--primary-color);
    --secondary-color: #ffd6bc;

    --accent-text-color: var(--primary-color);
}

:root [data-community="ydn"] {
    --primary-color: #6c30f5;
    --primary-background-color: var(--primary-color);
    --secondary-color: #e7b3ff;

    --accent-text-color: var(--primary-color);
}

:root [data-community="phystech4ever"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="clubfirstfuture"] {
    --primary-color: #0b4291;
    --primary-background-color: var(--primary-color);
    --secondary-color: #77a4e2;

    --accent-text-color: var(--primary-color);
}

:root [data-community="gdgtashkent"] {
    --primary-color: #5587fc;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="altacommunity"] {
    --primary-color: #25b187;
    --primary-background-color: var(--primary-color);
    --secondary-color: #e6fbf5;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
}

:root [data-community="eastmeetswestclub"] {
    --primary-color: #059b69;
    --primary-background-color: var(--primary-color);
    --secondary-color: #98e7cd;

    --accent-text-color: var(--primary-color);
}

:root [data-community="connect-entire"] {
    --primary-color: #145da0;
    --primary-background-color: var(--primary-color);
    --secondary-color: #b1d4e0;

    --accent-text-color: var(--primary-color);
}

:root [data-community="alumni"] {
    --primary-color: #00204d;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #f3f8ff;

    --primary-text-color: #00204d;
    --secondary-text-color: #0a0a0a;
    --accent-text-color: #e04130;
    --error-color: var(--accent-text-color);
}

:root [data-community="connect"] {
    --primary-color: #00204d;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #f3f8ff;

    --primary-text-color: #00204d;
    --secondary-text-color: #212529;
    --accent-text-color: #e01600;
    --error-color: #e04130;
}

:root [data-community="sfih"] {
    --primary-color: #0b4291;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgb(240, 245, 250);
    --secondary-color: rgb(240, 245, 250);

    --primary-text-color: #0b4291;
    --secondary-text-color: #0a0a0a;
    --accent-text-color: #77a4e2;
}

:root [data-community="mentor"] {
    --primary-color: #00204d;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #f3f8ff;

    --primary-text-color: #00204d;
    --secondary-text-color: #0a0a0a;
    --accent-text-color: #e04130;
    --error-color: var(--accent-text-color);
}

:root [data-community="30ka"] {
    --primary-color: #003369;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #f3f8ff;
    --primary-text-color: #00204d;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: #046dcf;
}

:root [data-community="sfihmiami"] {
    --primary-color: #0b4291;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgb(240, 245, 250);
    --secondary-color: rgb(240, 245, 250);

    --primary-text-color: #0b4291;
    --secondary-text-color: #0a0a0a;
    --accent-text-color: #77a4e2;
}

:root [data-community="suncapitalclub"] {
    --primary-color: #15161a;
    --primary-background-color: var(--primary-color);
    --secondary-color: #F4DC9F;

    --accent-text-color: var(--primary-color);
}

:root [data-community="brnetwork"] {
    --primary-color: #89a02e;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="linkday"] {
    --primary-color: #45b8d4;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechmeetup"] {
    --primary-color: #3D5A80;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: #ebf1f963;
    --secondary-color: #DDEAF1;
    --primary-text-color: #3D5A80;
    --secondary-text-color: #626262;
    --accent-text-color: #FFC623;
    --error-color: #E9624E;
}

:root [data-community="obs"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="hello"] {
    --primary-color: #FF0244;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="play"] {
    --primary-color: #ef426f;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechmexico"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechtoronto"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechchicago"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechargentina"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechmontenegro"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="aescmsu"] {
    --primary-color: #16559a;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(237, 246, 246, 0.35);
    --secondary-color: #edf6f6;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #b4242a;
}

:root [data-community="suffolkconnect"] {
    --primary-color: #1f3861;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.35);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechtallinn"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechnewyork"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechswitzerland"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="rak"] {
    --primary-color: #c21523;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechsanfrancisco"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="biban24"] {
    --primary-color: #3D5A80;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: #ebf1f963;
    --secondary-color: #DDEAF1;
    --primary-text-color: #3D5A80;
    --secondary-text-color: #626262;
    --accent-text-color: #FFC623;
    --error-color: #E9624E;
}

:root [data-community="brbnetwork"] {
    --primary-color: #e52716;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechberlin"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="unibeconnect"] {
    --primary-color: #B43839;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechmiami"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="swgfoundersclub"] {
    --primary-color: #ee203e;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechreunionrak"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="hubcollab"] {
    --primary-color: #5B56B0;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechregatta"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}

:root [data-community="phystechlondon"] {
    --primary-color: #2860E1;
    --primary-background-color: var(--primary-color);
    --secondary-background-color: rgba(240, 245, 250, 0.5);
    --secondary-color: #EBF1F9;
    --primary-text-color: #1E3348;
    --secondary-text-color: rgba(30, 51, 72, 0.70);
    --accent-text-color: var(--primary-color);
    --error-color: #E9624E;
}